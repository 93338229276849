/* eslint-disable react/no-array-index-key */
import React from 'react'
import _ from 'lodash'
import { Button } from '../../../../../ui'
import useStyles from './info-style'

const InfoView = ({
  address, title, onClick, textButton, showText, disabled,
}) => {
  const { infoStyle, boxTilte, buttonInfoStyle } = useStyles({ showText })
  const phoneFormmatted = _.get(address, 'phoneData.formatted', null)
  return (
    <div className={infoStyle}>
      <div className={boxTilte}>
        <h3>{title}</h3>
        <Button
          onClick={onClick}
          border
          mini
          className={buttonInfoStyle}
          text={textButton}
          disabled={disabled}
        />
      </div>
      {
        showText && !_.isEmpty(address) && (
          <>
            <p>{`${address.firstName} ${address.lastName}`}</p>
            {
              address.formattedAddressLines.map((line, index) => (
                <p key={`address-${line.id}-${index}`}>{line}</p>
              ))
            }
            {
              phoneFormmatted && (
                <>
                  <br />
                  <p>{phoneFormmatted}</p>
                </>
              )
            }
          </>
        )
      }

    </div>

  )
}
export default InfoView
