import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [[30, 20]],
    width: '100%',
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBox: {
    '& h1': {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: 1.25,
      margin: 0,
      textTransform: 'uppercase',
    },
    '& p': {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: 1.25,
      margin: 0,
      color: theme.colors.bgDark,
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      padding: [[60, 0]],
      width: '100%',
      textAlign: 'left',
    },
    container: {
      maxWidth: '67rem',
    },
  },
}))

export default useStyles
