import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  infoStyle: (props) => ({
    paddingTop: '3rem',
    marginBottom: props.showText ? '3rem' : 0,
    textAlign: 'left',
    width: '100%',
    '& h3': {
      fontSize: '1.67rem',
      fontWeight: 600,
      marginBottom: '1.1rem',
      marginTop: '0.5rem',
      textTransform: 'uppercase',
    },
    '& p': {
      marginBottom: 0,
      lineHeight: 1.43,
      fontSize: '1.4rem',
      '& span': {
        marginLeft: '.5rem',
      },
    },
  }),
  boxTilte: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  buttonInfoStyle: {
    flex: '0 0 auto',
  },

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    infoStyle: {
      width: '37rem',
      '& h4': {
        marginBottom: '2.2rem',
      },
    },

  },
}))

export default useStyles
