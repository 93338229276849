import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddressManager,
  Layout,
  Loading,
  SEO,
} from '../../../ui'
import Info from './components'
import useStyles from './account-addresses-style'

function AccountAddressesView({
  defaultBillingAddress,
  defaultDeliveryAddress,
  deliveryAddresses,
  isAllowedBillingAddress,
  isEditBilling,
  isEditDelivery,
  isLoading,
  pageReady,
  selectedAddress,
  seoTitle,
  onCancel,
  onDeleteAddress,
  onEditBilling,
  onEditDelivery,
  onFetchDeliveryAddresses,
  onSaveBilling,
  onSaveDelivery,
  onSelectAddress,
  onUpdateDeliveryAddress,
}) {
  // prepare hook
  const { t } = useTranslation()

  // style
  const { containerFluid, container, titleBox } = useStyles()

  return (
    <Layout login>
      <SEO title={seoTitle} />
      {
        !pageReady
          ? (
            <Loading />
          )
          : (
            <div className={containerFluid}>
              <div className={container}>
                <div className={titleBox}>
                  <h1>{t('screens.account.shipping.title')}</h1>
                  <p>{t('screens.account.shipping.subtitle')}</p>
                </div>

                {/* Delivery Address */}
                <Info
                  address={defaultDeliveryAddress}
                  textButton={t(`screens.account.shipping.buttons.${isEditDelivery ? 'save' : 'manage'}`)}
                  title={t('screens.account.shipping.shipping')}
                  onClick={isEditDelivery ? onSaveDelivery : onEditDelivery}
                  disabled={isEditBilling}
                  showText={!isEditDelivery}
                />
                {
                  isEditDelivery && (
                    <AddressManager
                      addresses={deliveryAddresses}
                      addressType="delivery"
                      defaultAddress={selectedAddress}
                      selectedAddress={selectedAddress}
                      onAddress
                      onClose={onCancel}
                      onDeleteAddress={onDeleteAddress}
                      onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                      onSetDefaultAddress={onSelectAddress}
                      onSelectAddress={onSelectAddress}
                      onUpdateDeliveryAddress={onUpdateDeliveryAddress}
                    />
                  )
                }
                {
                  isAllowedBillingAddress
                  && (
                  <>
                    {/* Billing Address */}
                    <Info
                      address={defaultBillingAddress}
                      textButton={t(`screens.account.shipping.buttons.${isEditBilling ? 'save' : 'manage'}`)}
                      title={t('screens.account.shipping.billing')}
                      onClick={isEditBilling ? onSaveBilling : onEditBilling}
                      disabled={isEditDelivery}
                      showText={!isEditBilling}
                    />
                    {
                      isEditBilling && (
                        <AddressManager
                          addresses={deliveryAddresses}
                          addressType="billing"
                          defaultAddress={selectedAddress}
                          selectedAddress={selectedAddress}
                          onAddress
                          onClose={onCancel}
                          onDeleteAddress={onDeleteAddress}
                          onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                          onSetDefaultAddress={onSelectAddress}
                          onUpdateDeliveryAddress={onUpdateDeliveryAddress}
                        />
                      )
                    }
                  </>
                  )
                }
              </div>
              { isLoading && <Loading /> }
            </div>
          )
      }
    </Layout>
  )
}

export default AccountAddressesView
